export const LOGIN = '/login'

export const DASHBOARD = '/'
export const MANAGE_CITIZENS = '/manage-citizens'
export const MANAGE_POLITICIANS = '/manage-politicians'
export const MANAGE_OFFICERS = '/manage-officers'
export const MANAGE_DESIGNATIONS = '/manage-designations'
export const MANAGE_CONSTITUENCIES = '/manage-constituencies'
export const LIST_CONSTITUENCIES = '/list-constituencies'
export const MANAGE_ISSUES = '/manage-issues'
export const REVIEW_ISSUES = '/review-issues'
export const ISSUE_DETAILS = '/issue-details'
export const MANAGE_ADMINS = '/manage-admins'
export const MANAGE_CITIES = '/manage-cities'
