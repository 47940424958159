import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { LOGIN, REVIEW_ISSUES } from './constants/ReactEndpoints'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') || false)

  useEffect(() => {}, [isLoggedIn])

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            exact
            path={LOGIN}
            name="Login Page"
            element={
              isLoggedIn ? <Navigate to={REVIEW_ISSUES} /> : <Login setIsLoggedIn={setIsLoggedIn} />
            }
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            path="*"
            name="Home"
            element={isLoggedIn ? <DefaultLayout /> : <Navigate to={LOGIN} />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
